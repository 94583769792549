import styled from '@emotion/styled'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Themed } from 'theme-ui'

import { PrimaryButtonLink } from '../components/UIComponents'
import { NotFoundEnglishLocale, NotFoundSwedishLocale } from '../locales'

/**
 * Renders the NotFoundPage component.
 *
 * @returns {HTMLElement} - The HTML Markup.
 */
const NotFoundPage = () => {
  const router = useRouter()
  const { locale } = router
  const t = locale === 'en' ? NotFoundEnglishLocale : NotFoundSwedishLocale

  return (
    <>
      <Head>
        <title>Sidan kunde inte hittas - JC Psykologmottagning AB</title>
        <meta name="Description" content="Sidan kunde inte visas." />
        <link rel="icon" href="/img/logo.svg" />
      </Head>
      <NotFoundPageStyles>
        <Themed.h1>404</Themed.h1>
        <Themed.p>
          {t.paragraphOne} <br /> {t.paragraphTwo}
        </Themed.p>
        <PrimaryButtonLink href="/">{t.linkOne}</PrimaryButtonLink>
      </NotFoundPageStyles>
    </>
  )
}

const NotFoundPageStyles = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 95%;
  min-height: inherit;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;

  p {
    margin-bottom: 2rem;
  }
`

export default NotFoundPage
